export const featureFlagConstants = {
  PLAYGROUND_ACCESS: 'playground-access',
  WAITLIST_ACCESS: 'waitlist-access',
  INTENT_AI: 'experience-intent-ai',
  DUPLICATE_EXPERIENCE: 'duplicate-experience',
  CHECKIN_ATTENDEES: 'checkin-attendees',
  VENUE_AI: 'venue-ai',
  HOST_COMMUNITY: 'host-community',
  EXPERIENCE_RESCHEDULE: 'experience-reschedule',
};
