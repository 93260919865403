import { Outlet, useLocation, useMatches } from 'react-router-dom';
import { useEffect } from 'react';
import { UIMatch, useNavigate } from 'react-router';
import { useAuth, Toaster } from '@cg/module-frontend';
import {
  Href,
  Loader,
  Banner,
  Footer,
  MobileScreen,
} from '@cg/module-frontend/src/components';
import { Handle } from '@cg/module-frontend/src/router';
import { ErrorBoundary, NotFound } from '@cg/module-frontend/src/errors';
import { withAuthentication } from '@cg/module-frontend/src/core';
import { PostHogAnalytics } from '@cg/module-frontend/src/analytics';
import Header from './Header';
import BottomNavigation from './BottomNavigation';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function MainLayout() {
  const matches = useMatches() as UIMatch<unknown, Handle>[];
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const { self, fetchingSelf, requiresSignup, login, hasFlag } = useAuth();

  const featureFlags = matches.filter(
    (match) => match.handle?.featureFlags?.length,
  );

  const requiresAuth =
    matches.filter((match) => Boolean(match.handle?.requiresAuth)).length > 0;
  const isSignup = ['/signup', '/login'].includes(pathname);

  useEffect(() => {
    // Accessing a page that requires signup
    if (requiresAuth && requiresSignup && !isSignup) {
      navigate({
        pathname: '/signup',
        search,
      });
    }

    // Accessing page that requires auths
    if (requiresAuth && !self && !fetchingSelf) {
      login();
    }

    if (featureFlags.length !== 0) {
      const flagMissing = featureFlags.some((match) => {
        return match.handle.featureFlags?.some((flag) => {
          return !hasFlag(flag);
        });
      });

      if (flagMissing) {
        throw new NotFound('Page not found');
      }
    }
  }, [self, fetchingSelf, pathname]);

  const Component = requiresAuth ? withAuthentication(Outlet) : Outlet;

  return (
    <>
      <PostHogAnalytics />
      <Toaster
        toastOptions={{
          className: 'border lg:min-w-96 min-w-80',
        }}
      />
      <Header />
      {self && requiresSignup && !isSignup && (
        <Banner>
          Welcome back! Finish <Href to="/signup">signing up</Href> to access
          all our features.
        </Banner>
      )}

      <main className="min-h-[40vh] pb-20 md:pb-0">
        <Loader horizontal loading={fetchingSelf}>
          <ErrorBoundary>
            <Component />
          </ErrorBoundary>
        </Loader>
        <Footer />
      </main>
      <ScrollToTop />
      <MobileScreen>
        <BottomNavigation />
      </MobileScreen>
    </>
  );
}

export default MainLayout;
