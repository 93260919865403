import { Gender } from '~/generated/models/Gender';
import { DietaryRestriction } from '~/generated/models/DietaryRestriction';
import { AddressOrCoordinate } from '~/generated/models/AddressOrCoordinate';
import { PaymentDetailMethod } from '~/generated/models/PaymentDetailMethod';

export const PaymentDetailMethodMap = new Map<PaymentDetailMethod, string>([
  [PaymentDetailMethod.Wise, 'Wise'],
  [PaymentDetailMethod.Interac, 'Interac eTransfer'],
]);

export const GenderMap = new Map<Gender, string>([
  [Gender.Man, 'Man'],
  [Gender.Woman, 'Woman'],
  [Gender.NonBinary, 'Non-binary'],
  [Gender.TransgenderMan, 'Transgender Man'],
  [Gender.TransgenderWoman, 'Transgender Woman'],
  [Gender.GenderFluid, 'Genderfluid'],
  [Gender.TwoSpirit, 'Two-spirit'],
  [Gender.Agender, 'Agender'],
  [Gender.OptOut, 'Opt-Out'],
  [Gender.Other, 'Other'],
]);

export const DietMap = new Map<DietaryRestriction, string>([
  [DietaryRestriction.None, 'None'],
  [DietaryRestriction.Other, 'Other'],
  [DietaryRestriction.Vegetarian, 'Vegetarian'],
  [DietaryRestriction.Vegan, 'Vegan'],
  [DietaryRestriction.GlutenFree, 'Gluten Free'],
  [DietaryRestriction.Pescatarian, 'Pescatarian'],
  [DietaryRestriction.DairyFree, 'Dairy Free'],
  [DietaryRestriction.NutFree, 'Nut Free'],
  [DietaryRestriction.Kosher, 'Kosher'],
  [DietaryRestriction.Halal, 'Halal'],
]);

export const byteToMb = (byte: number) => byte / (1024 * 1024);

export const addressString = (address: AddressOrCoordinate | null) => {
  if (!address?.street1) {
    return 'Vancouver, BC';
  }

  const prefix = address.street2 ? `#${address.street2} - ` : '';
  const str: string[] = [];
  if (prefix.trim()) {
    str.push(prefix.trim());
  }
  if (address.street1.trim()) {
    str.push(address.street1.trim());
  }
  if (address.city?.trim()) {
    str.push(address.city.trim());
  }
  if (address.state?.trim() || address.code?.trim()) {
    str.push(`${address.state?.trim()} ${address.code?.trim()}`);
  }
  if (address.country?.trim()) {
    str.push(address.country?.trim());
  }

  return str.join(', ');
};
