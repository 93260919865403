import { createContext, useContext } from 'react';
import { Experience } from '~/generated/models/Experience';
import { Host } from '~/generated/models/Host';
import { HostUserProfile } from '~/generated/models/HostUserProfile';

export type HostContextType = {
  host: Host | null;
  hostUsers: HostUserProfile[];
  fetchingHost: boolean;
  hostExperiences: Experience[];
};

export const HostContext = createContext<HostContextType>({
  host: null,
  hostUsers: [],
  fetchingHost: false,
  hostExperiences: [],
});

export function useHost() {
  return useContext(HostContext);
}
