import { createContext, useContext } from 'react';
import { ExperienceTicketTiersResponse } from '~/generated/models/ExperienceTicketTiersResponse';
import { Experience } from '~/generated/models/Experience';
import { TicketResponse } from '~/generated/models/TicketResponse';

const stub = (): never => {
  throw new Error(
    'You forgot to wrap your component in <ExperienceContextProvider>.',
  );
};

export type ExperienceContextType = {
  tiers: ExperienceTicketTiersResponse[];
  experience: Experience | null;
  isPast: boolean;
  saleEnded: boolean;
  saleEndDate: Date;
  identifier: string;
  joinWaitlist: () => void;
  toggleInterested: () => void;
  togglingInterested: boolean;
  waitlisted: boolean;
  interested: boolean;
  availableTickets: number;
  fetchingExperience: boolean;
  purchasedTickets: TicketResponse[];
};

export const ExperienceContext = createContext<ExperienceContextType>({
  tiers: [],
  identifier: '',
  experience: null,
  isPast: false,
  saleEnded: false,
  saleEndDate: new Date(),
  joinWaitlist: stub,
  toggleInterested: stub,
  togglingInterested: false,
  waitlisted: false,
  interested: false,
  availableTickets: 0,
  fetchingExperience: true,
  purchasedTickets: [],
});

export function useExperience() {
  return useContext(ExperienceContext);
}
