import React from 'react';
import { useScreenSize } from '../../hooks';

type MobileScreenProps = {
  children: React.ReactElement | React.ReactElement[] | null;
};

function MobileScreen({ children }: MobileScreenProps) {
  const { isSmall } = useScreenSize();
  if (!isSmall) {
    return null;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

export default MobileScreen;
