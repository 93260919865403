import { ErrorBoundary as ReactErrorBound } from 'react-error-boundary';
import React from 'react';
import { ClientException } from '@tsed/exceptions';
import ClientExceptionBoundary from './ClientExceptionBoundary';

type FallbackRenderProps = {
  error: Error;
  resetErrorBoundary: () => void;
};

function fallbackRender({ error }: FallbackRenderProps) {
  if (error instanceof ClientException) {
    return ClientExceptionBoundary(error);
  }

  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: 'red' }}>{error.message}</pre>
    </div>
  );
}

type ErrorBoundaryProps = {
  children: React.ReactElement;
};

function ErrorBoundary({ children }: ErrorBoundaryProps) {
  return (
    <ReactErrorBound fallbackRender={fallbackRender}>
      {children}
    </ReactErrorBound>
  );
}

export default ErrorBoundary;
