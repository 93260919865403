import { createBrowserRouter } from 'react-router-dom';
import React, { lazy } from 'react';

import { CGRouteObject } from '@cg/module-frontend/src/router';
import { featureFlagConstants } from '@cg/common/src/constants';
import { Suspense } from '@cg/module-frontend/src/components';
import { PageNotFound } from '@cg/module-frontend/src/errors';
import { MainLayout } from '~/app/layouts';
import RSVPCalendar from '~/pages/rsvp';

// Layouts
const ExperienceLayout = lazy(() =>
  import('~/app/layouts').then((m) => ({ default: m.ExperienceLayout })),
);
const HostLayout = lazy(() =>
  import('~/app/layouts').then((m) => ({ default: m.HostLayout })),
);
const ProfileLayout = lazy(() =>
  import('~/app/layouts').then((m) => ({ default: m.ProfileLayout })),
);

// Generic Pages
const BecomeAHostPage = lazy(() => import('~/pages/become-a-host'));
const VibeCheckPage = lazy(() => import('~/pages/vibe-check'));
const AboutUsPage = lazy(() => import('~/pages/about'));
const ContactUsPage = lazy(() => import('~/pages/contact-us'));
const PrivacyPolicyPage = lazy(() => import('~/pages/legal/PrivacyPolicy'));
const ToSPage = lazy(() => import('~/pages/legal/ToS'));
const CookiePolicyPage = lazy(() => import('~/pages/legal/CookiePolicy'));

// Experiences Page
const HomePage = lazy(() => import('~/pages/home'));

// Signup/Login
const SignupPage = lazy(() => import('~/pages/signup'));
const LoginPage = lazy(() => import('~/pages/login'));

// Experience Pages
const ExperiencePage = lazy(() => import('~/pages/experience'));
const CheckoutPage = lazy(() => import('~/pages/experience/checkout'));
const CheckoutSucceededPage = lazy(() =>
  import('~/pages/experience/confirmation').then((m) => ({
    default: m.CheckoutSucceeded,
  })),
);
const CheckoutFailedPage = lazy(() =>
  import('~/pages/experience/confirmation').then((m) => ({
    default: m.CheckoutFailed,
  })),
);
const WaitlistedPage = lazy(() =>
  import('~/pages/experience/confirmation').then((m) => ({
    default: m.Waitlisted,
  })),
);

// Hosts pages
const HostsPage = lazy(() => import('~/pages/hosts'));
const HostPage = lazy(() => import('~/pages/hosts/host'));
const HostExperiencePage = lazy(() => import('~/pages/hosts/host/experiences'));

// Profile pages
const ProfileDetailsPage = lazy(() => import('~/pages/profile/details'));
const ProfileExperiencesPage = lazy(
  () => import('~/pages/profile/experiences'),
);
const ProfileExperiencesReceiptPage = lazy(
  () => import('~/pages/profile/experiences/receipt'),
);

export const routes: CGRouteObject[] = [
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: (
          <Suspense>
            <HomePage />
          </Suspense>
        ),
        handle: {
          requiresAuth: false,
        },
      },
      {
        path: 'rsvp-calendar',
        element: <RSVPCalendar />,
        handle: {
          requiresAuth: false,
        },
      },
      {
        path: 'become-a-host',
        element: (
          <Suspense>
            <BecomeAHostPage />
          </Suspense>
        ),
        handle: {
          requiresAuth: false,
        },
      },
      {
        path: '/login',
        element: (
          <Suspense>
            <LoginPage />
          </Suspense>
        ),
        handle: {
          requiresAuth: true,
        },
      },
      {
        path: '/e',
        element: (
          <Suspense>
            <ExperienceLayout />
          </Suspense>
        ),
        handle: {
          requiresAuth: false,
        },
        children: [
          {
            index: true,
            handle: {
              requiresAuth: false,
            },
          },
          {
            path: '/e/:identifier',
            children: [
              {
                index: true,
                element: (
                  <Suspense>
                    <ExperiencePage />
                  </Suspense>
                ),
                handle: {
                  requiresAuth: false,
                },
              },
              {
                path: '/e/:identifier/checkout',
                element: (
                  <Suspense>
                    <CheckoutPage />
                  </Suspense>
                ),
                handle: {
                  requiresAuth: true,
                },
              },
              {
                path: '/e/:identifier/success',
                element: (
                  <Suspense>
                    <CheckoutSucceededPage />
                  </Suspense>
                ),
                handle: {
                  requiresAuth: true,
                },
              },
              {
                path: '/e/:identifier/failed',
                element: (
                  <Suspense>
                    <CheckoutFailedPage />
                  </Suspense>
                ),
                handle: {
                  requiresAuth: true,
                },
              },
              {
                path: '/e/:identifier/waitlisted',
                element: (
                  <Suspense>
                    <WaitlistedPage />
                  </Suspense>
                ),
                handle: {
                  requiresAuth: true,
                },
              },
            ],
          },
        ],
      },
      {
        path: '/profile',
        handle: {
          requiresAuth: true,
        },
        element: (
          <Suspense>
            <ProfileLayout />
          </Suspense>
        ),
        children: [
          {
            path: '/profile/experiences',
            element: (
              <Suspense>
                <ProfileExperiencesPage />
              </Suspense>
            ),
          },
          {
            path: '/profile/details',
            element: (
              <Suspense>
                <ProfileDetailsPage />
              </Suspense>
            ),
          },
          {
            path: '/profile/experiences/:identifier',
            element: (
              <Suspense>
                <ProfileExperiencesReceiptPage />
              </Suspense>
            ),
            handle: {
              featureFlags: [featureFlagConstants.WAITLIST_ACCESS],
            },
          },
        ],
      },
      {
        path: '/h',
        handle: {
          requiresAuth: false,
        },
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <HostsPage />
              </Suspense>
            ),
          },
          {
            path: '/h/:hostUniqueName',
            element: (
              <Suspense>
                <HostLayout />
              </Suspense>
            ),
            children: [
              {
                index: true,
                element: (
                  <Suspense>
                    <HostPage />
                  </Suspense>
                ),
              },
              {
                path: '/h/:hostUniqueName/experiences',
                element: (
                  <Suspense>
                    <HostExperiencePage />
                  </Suspense>
                ),
              },
              // {
              //   path: '/h/:hostUniqueName/about',
              //   element: (
              //     <Suspense>
              //       <HostPage tab={HostTabs.About} />
              //     </Suspense>
              //   ),
              //   handle: {
              //     featureFlags: [featureFlagConstants.HOST_COMMUNITY],
              //   },
              // },
              // {
              //   path: '/h/:hostUniqueName/members',
              //   element: (
              //     <Suspense>
              //       <HostPage tab={HostTabs.Members} />
              //     </Suspense>
              //   ),
              //   handle: {
              //     featureFlags: [featureFlagConstants.HOST_COMMUNITY],
              //   },
              // },
            ],
          },
        ],
      },
      {
        path: 'vibe-check',
        element: (
          <Suspense>
            <VibeCheckPage />
          </Suspense>
        ),
        handle: {
          requiresAuth: false,
        },
      },
      {
        path: 'about-us',
        element: (
          <Suspense>
            <AboutUsPage />
          </Suspense>
        ),
        handle: {
          requiresAuth: false,
        },
      },
      {
        path: 'privacy-policy',
        element: (
          <Suspense>
            <PrivacyPolicyPage />
          </Suspense>
        ),
        handle: {
          requiresAuth: false,
        },
      },
      {
        path: 'cookie-policy',
        element: (
          <Suspense>
            <CookiePolicyPage />
          </Suspense>
        ),
        handle: {
          requiresAuth: false,
        },
      },
      {
        path: 'ToS',
        element: (
          <Suspense>
            <ToSPage />
          </Suspense>
        ),
        handle: {
          requiresAuth: false,
        },
      },
      {
        path: 'contact-us',
        element: (
          <Suspense>
            <ContactUsPage />
          </Suspense>
        ),
      },
      {
        path: 'signup',
        element: (
          <Suspense>
            <SignupPage />,
          </Suspense>
        ),
        handle: {
          requiresAuth: true,
        },
      },
      {
        path: '*',
        element: <PageNotFound />,
      },
    ],
  },
];

const router = createBrowserRouter(routes);

export default router;
