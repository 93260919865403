import { Link, LinkProps } from 'react-router-dom';
import * as React from 'react';
import classNames from 'classnames';

type HrefProps = LinkProps &
  React.RefAttributes<HTMLAnchorElement> & {
    reload?: boolean;
  };

export function Href(props: HrefProps) {
  const { children, className } = props;

  return (
    <Link
      className={classNames(
        'underline text-primary hover:text-primary-darker visited:text-primary',
        className,
      )}
      onClick={(e) => {
        // eslint-disable-next-line
        if (props.reload && props.to && typeof props.to === 'string') {
          e.preventDefault();
          e.stopPropagation();
          // eslint-disable-next-line
          window.location.href = props.to;
        }
      }}
      {...props}
    >
      {children}
    </Link>
  );
}
