import { Link } from 'react-router-dom';
import { ArrowRightIcon, TicketIcon } from '@cg/module-frontend/src/icons';
import { ExperienceStartingPrice } from '@cg/module-frontend/src/components/experience';
import { Button } from '@cg/module-frontend/src/components';
import { useExperience } from '~/app/context/experience';

export function GrabTicket() {
  const {
    tiers,
    waitlisted,
    availableTickets,
    identifier,
    joinWaitlist,
    saleEnded,
  } = useExperience();

  return (
    <div className="relative flex flex-row justify-evenly bg-primary h-full w-full py-6">
      <div className="items-center justify-center flex flex-row gap-x-2">
        <ExperienceStartingPrice tiers={tiers} />
      </div>
      {availableTickets ? (
        <Link to={`/e/${identifier}/checkout`} className="flex">
          <Button
            type="button"
            color="secondary"
            icon={<TicketIcon className="text-primary size-6" />}
            disabled={saleEnded}
          >
            <span className="flex items-center">Grab a Ticket</span>
          </Button>
        </Link>
      ) : (
        <Button
          disabled={waitlisted || saleEnded || false}
          color="secondary"
          onClick={joinWaitlist}
          icon={<ArrowRightIcon className="text-primary size-6" />}
        >
          <span className="flex items-center">Join Waitlist</span>
        </Button>
      )}
    </div>
  );
}
