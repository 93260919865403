import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../core/auth';
import {
  FacebookIcon,
  InstagramIcon,
  TikTokIcon,
  WhatsAppIcon,
} from '../../icons';
import { boxConfig } from '../../config';
import { Href } from '../href';

function Footer() {
  const { isLogged } = useAuth();

  return (
    <footer className="w-full py-3 md:py-14 bg-grey">
      <div className="w-mobile lg:w-desktop mx-auto lg:text-left text-center">
        <div className="container_footer flex flex-col lg:flex-row justify-center lg:justify-start items-center lg:items-start">
          <div className="w-full lg:w-auto mb-6 lg:mb-0 lg:mr-6 flex justify-center lg:justify-start">
            <div>
              <span className="text-black text font-semibold leading-snug">
                Common Ground
                <br />
              </span>
              <ul className="flex flex-col items-center lg:items-start">
                <li>
                  <Link
                    to="/"
                    className="text-black text-md font-light leading-tight"
                  >
                    Home Page
                  </Link>
                </li>
                <li>
                  <Link
                    to="/vibe-check"
                    className="text-black text-md font-light leading-tight"
                  >
                    Vibe Check
                  </Link>
                </li>
                <li>
                  <Href
                    to="https://bit.ly/45dKir8"
                    target="_blank"
                    className="text-black text-md font-light leading-tight"
                  >
                    WhatsApp Community
                  </Href>
                </li>
              </ul>
            </div>
          </div>
          <div className="custom_border" />
          <div className="w-full lg:w-auto mb-6 lg:mb-0 lg:mr-6 flex justify-center lg:justify-start">
            <div>
              <span className="text-black text font-semibold leading-snug">
                Playground
                <br />
              </span>
              <ul className="flex flex-col items-center lg:items-start">
                <li>
                  <Link
                    to={`${boxConfig.baseUrls.commonGround}/become-a-host`}
                    className="text-black text-md font-light leading-tight"
                  >
                    Become a Host
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="custom_border" />
          <div className="w-full lg:w-auto lg:ml-6 mb-6 lg:mb-0 flex justify-center lg:justify-start">
            <div>
              <span className="text-black text font-semibold leading-snug">
                You
                <br />
              </span>
              <ul className="flex flex-col items-center lg:items-start">
                {!isLogged && (
                  <>
                    <li>
                      <Link
                        to={boxConfig.baseUrls.commonGround}
                        className="text-black text-md font-light leading-tight"
                      >
                        Sign Up
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={boxConfig.baseUrls.commonGround}
                        className="text-black text-md font-light leading-tight"
                      >
                        Login
                      </Link>
                    </li>
                  </>
                )}
                {isLogged && (
                  <>
                    <li>
                      <Link
                        to={`${boxConfig.baseUrls.commonGround}/profile/experiences`}
                        className="text-black text-md font-light leading-tight"
                      >
                        My Experiences
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`${boxConfig.baseUrls.commonGround}/profile/details`}
                        className="text-black text-md font-light leading-tight"
                      >
                        My Profile
                      </Link>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
          <div className="custom_border" />
          <div className="w-full lg:w-auto flex justify-center lg:justify-start">
            <div>
              <span className="text-black text font-semibold leading-snug">
                Us
                <br />
              </span>
              <ul className="flex flex-col items-center lg:items-start">
                <li>
                  <Link
                    to={`${boxConfig.baseUrls.commonGround}/about-us`}
                    className="text-black text-md font-light leading-tight"
                  >
                    About
                  </Link>
                </li>
                <li>
                  <Link
                    to={`${boxConfig.baseUrls.commonGround}/contact-us`}
                    className="text-black text-md font-light leading-tight"
                  >
                    Get in Touch
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="custom_border" />
          <div className="w-full lg:w-fit flex justify-center lg:justify-start">
            <div className="flex-1">
              <span className="text-black lg:text-center lg:ml-3 text font-semibold leading-snug">
                Let&apos;s be Friends
              </span>
              <div className="grid grid-cols-2 place-items-center w-full gap-y-4 mt-3 items-center">
                <Href
                  to="https://www.facebook.com/joincommonground"
                  target="_blank"
                  className="text-black"
                >
                  <FacebookIcon className="w-10 h-10" />
                </Href>
                <Href
                  to="https://www.tiktok.com/@joincommonground"
                  target="_blank"
                  className="text-black"
                >
                  <TikTokIcon className="w-10 h-10" />
                </Href>
                <Href
                  to="https://bit.ly/45dKir8"
                  target="_blank"
                  className="text-black"
                >
                  <WhatsAppIcon className="w-10 h-10" />
                </Href>
                <Href
                  to="https://www.instagram.com/joincommonground"
                  target="_blank"
                  className="text-black"
                >
                  <InstagramIcon className="w-10 h-10" />
                </Href>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full bg-secondary md:my-2 lg:my-5" />
        <div className="flex flex-col lg:flex-row justify-center lg:justify-between items-center lg:items-center lg:mb-0 md:mb-20 text-center lg:text-left">
          <span className="text-zinc-500 text-base font-medium leading-tight tracking-tight">
            © 2024 Common Ground Social Network Inc.
          </span>
          <div className="flex flex-col lg:flex-row lg:items-center lg:gap-10">
            <span className="text-zinc-500 text-base font-medium leading-tight tracking-tight">
              <Link to={`${boxConfig.baseUrls.commonGround}/tos`}>
                Terms of Service
              </Link>{' '}
              |{' '}
              <Link to={`${boxConfig.baseUrls.commonGround}/privacy-policy`}>
                Privacy Policy
              </Link>{' '}
              |{' '}
              <Link to={`${boxConfig.baseUrls.commonGround}/cookie-policy`}>
                {' '}
                Cookie Policy
              </Link>{' '}
              |{' '}
              <Link to={`${boxConfig.baseUrls.commonGround}/contact-us`}>
                Help
              </Link>
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
