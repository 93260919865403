import { usePostHog } from 'posthog-js/react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../core/auth';

const ORIGIN_REGEX = /https:\/\/(dev|stage)\.(.*)/;
const EXPERIENCE_REGEX = /^\/e\/([^/]+?)--(.+?)$/;

export default function PostHogAnalytics() {
  const location = useLocation();
  const posthog = usePostHog();
  const { self } = useAuth();

  const originMatch = window.origin.match(ORIGIN_REGEX);
  const experienceMatch = location.pathname.match(EXPERIENCE_REGEX);

  //  track page views
  useEffect(() => {
    if (!posthog) {
      return;
    }

    const properties: Record<string, string> = {
      $current_url: `${window.origin.replace(/^\/|\/$/g, '')}/${location.pathname.replace(/^\/|\/$/g, '')}`,
      $$pathname: location.pathname,
      $$environment: 'local',
      $$domain: 'localhost',
      $$viewType: 'default',
    };
    if (originMatch) {
      // eslint-disable-next-line prefer-destructuring
      properties.$$environment = originMatch[1];
      // eslint-disable-next-line prefer-destructuring
      properties.$$domain = originMatch[2].replace('.com', '');
    } else {
      properties.$$environment = 'prod';
      properties.$$domain = window.origin
        .replace('.com', '')
        .replace('https://', '');
    }

    if (experienceMatch) {
      const hostName = experienceMatch[1];
      const experienceName = experienceMatch[2];
      properties.$$viewType = 'experience';
      properties.$$experience = experienceName;
      properties.$$host = hostName;
    }

    if (self) {
      properties.$$user = self.user.id.getValue();
    }

    posthog.capture('$pageview', properties);
  }, [location.pathname, posthog]);

  return null;
}
